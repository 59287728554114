import _modal from "./modal";
import _platform from "./platform";
import _sessionToken from "./session-token";
import _authorizationCode from "./authorization-code";
var exports = {};
var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
__exportStar(_modal, exports);
__exportStar(_platform, exports);
__exportStar(_sessionToken, exports);
__exportStar(_authorizationCode, exports);
export default exports;
export const __esModule = exports.__esModule,
  createMutationObserver = exports.createMutationObserver,
  setupModalAutoSizing = exports.setupModalAutoSizing,
  isShopifyPing = exports.isShopifyPing,
  isShopifyPOS = exports.isShopifyPOS,
  isShopifyMobile = exports.isShopifyMobile,
  isShopifyEmbedded = exports.isShopifyEmbedded,
  isMobile = exports.isMobile,
  getSessionToken = exports.getSessionToken,
  authenticatedFetch = exports.authenticatedFetch,
  getAuthorizationCodePayload = exports.getAuthorizationCodePayload,
  userAuthorizedFetch = exports.userAuthorizedFetch;